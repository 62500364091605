import React, { useState } from "react";
import "./ConfigurationPanel.css";
import { Configuration, Quadrant, CommandStyle, InitiativeStyle, ActionLimit, presetConfigurations } from "./configuration";

// type Stage = 'quadrant' | 'initiative';

type ConfigurationPanelProps = {
    initialConfiguration: Configuration,
    closeFn: () => void,
    applyFn: (Configuration) => void
}

export const ConfigurationPanel = ({ initialConfiguration, closeFn, applyFn }: ConfigurationPanelProps) => {
    // const [stage, setStage] = useState('quadrant' as Stage);
    const [selectedPreset, setSelectedPreset] = useState(initialConfiguration.id || 'custom');
    const [selectedQuadrant, setSelectedQuadrant] = useState('round_based' as Quadrant);
    const [selectedCommandStyle, setSelectedCommandStyle] = useState(initialConfiguration.commandStyle);
    const [selectedActionLimit, setSelectedActionLimit] = useState(initialConfiguration.actionLimit);
    const [selectedInitiativeStyle, setSelectedInitiativeStyle] = useState(initialConfiguration.initiativeStyle);
    const [isCustom, setIsCustom] = useState((initialConfiguration.id || 'custom') === 'custom');

    const cancel = () => {
        closeFn();
    }

    const apply = () => {
        applyFn({ id: selectedPreset, commandStyle: selectedCommandStyle, actionLimit: selectedActionLimit, initiativeStyle: selectedInitiativeStyle } as Configuration);
    }

    const selectPreset = (value: string) => {
        if (value === 'custom') {
            setSelectedPreset('custom');
            setIsCustom(true);
        } else {
            const newConfiguration = presetConfigurations[value];
            if (newConfiguration) {
                setSelectedPreset(newConfiguration.id);
                setSelectedCommandStyle(newConfiguration.commandStyle);
                setSelectedActionLimit(newConfiguration.actionLimit);
                setSelectedInitiativeStyle(newConfiguration.initiativeStyle);
                setIsCustom(false);
            }
        }
    }

    const selectCommandStyle = (value: CommandStyle) => {
        setSelectedCommandStyle(value);
    }

    const selectActionLimit = (value: ActionLimit) => {
        setSelectedActionLimit(value);
    }

    const selectInitiativeStyle = (value: InitiativeStyle) => {
        setSelectedInitiativeStyle(value);
    }

    return (
        <div className="modalHolder">
            <div className="modal">
                <div className="closeButton">
                    <button onClick={cancel}>X</button>
                </div>
                <div className="header">
                    <h2>Combat Settings</h2>
                </div>
                <div className="content">
                    <PresetSelector selectedPreset={selectedPreset} callback={selectPreset} />
                    <QuadrantStage selectedQuadrant={selectedQuadrant} locked={!isCustom}/>
                    <CommandStylePanel selectedCommandStyle={selectedCommandStyle} locked={!isCustom} callback={selectCommandStyle}/>
                    <InitiativeStylePanel selectedInitiativeStyle={selectedInitiativeStyle} locked={!isCustom} callback={selectInitiativeStyle}/>
                    <ActionLimitPanel selectedActionLimit={selectedActionLimit} locked={!isCustom} callback={selectActionLimit}/>
                </div>
                <div className="actions">
                    <button onClick={apply}>Apply & Restart</button>
                    <button onClick={cancel}>Cancel</button>
                </div>
            </div>
        </div>
    )
}

type PresetSelectorProps = {
    selectedPreset: string;
    callback: (string) => void
}

const PresetSelector = ({ selectedPreset, callback }: PresetSelectorProps) => {
    return (
    <>
        <label htmlFor="preset">Preset:</label>
        <select name="preset" id="preset" onChange={(event) => callback(event.target.value)}>
            <option value="custom">Custom</option>
            <hr></hr>
            {
                Object.values(presetConfigurations).map((preset) => {
                    return (
                        <option key={preset.id} value={preset.id} selected={preset.id === selectedPreset}>
                            {preset.label}
                        </option>
                    );
                })
            }
        </select>
    </>
    );
}

type QuadrantStageProps = {
    selectedQuadrant: Quadrant,
    locked: boolean
}

const QuadrantStage = ({ selectedQuadrant, locked }: QuadrantStageProps) => {
    return <fieldset>
        <legend>Quadrant</legend>
        <span className="custom_radio">
        <label>
            <input className="visually-hidden" disabled type="radio" name="quadrant" value="cycle_based"/>
            Cycle-Based
        </label>
        </span>
        <span className="custom_radio">
        <label>
            <input className="visually-hidden" disabled={locked} type="radio" name="quadrant" value="round_based" checked={selectedQuadrant === 'round_based'}/>
            Round-Based
        </label>
        </span>
        <span className="custom_radio">
        {/* <div style={{ gridColumn: 1, gridRow: 3 }}>Measured</div> */}
        <label>
            <input className="visually-hidden" disabled type="radio" name="quadrant" value="queue_based" />
            Queue-Based
        </label>
        </span>
        <span className="custom_radio">
        <label>
            <input className="visually-hidden" disabled type="radio" name="quadrant" value="clocked_queue_based" />
            Clocked Queue-Based
        </label>
        </span>
    </fieldset>
}

type CommandStylePanelProps = {
    selectedCommandStyle: CommandStyle;
    locked: boolean,
    callback: (value: CommandStyle) => void;
}

const CommandStylePanel = ({ selectedCommandStyle, locked, callback }: CommandStylePanelProps) => {
    return <fieldset>
        <legend>Command Style</legend>
        <span className="custom_radio">
        <label>
            <input className="visually-hidden" type="radio" name="command_style" value="immediate_action" checked={selectedCommandStyle === 'immediate'} disabled={locked} onChange={() => callback('immediate')}/>
            Immediate Action
        </label>
        </span>
        <span className="custom_radio">
        <label>
            <input className="visually-hidden" type="radio" name="command_style" value="intentions_phase" checked={selectedCommandStyle === 'intentions'} disabled={locked} onChange={() => callback('intentions')}/>
            Intentions Phase
        </label>
        </span>
    </fieldset>
}

type ActionLimitPanelProps = {
    selectedActionLimit: ActionLimit;
    locked: boolean,
    callback: (value: ActionLimit) => void;
}

const ActionLimitPanel = ({ selectedActionLimit, locked, callback }: ActionLimitPanelProps) => {
    return <fieldset>
        <legend>Action Limit</legend>
        <span className="custom_radio">
        <label>
            <input className="visually-hidden" type="radio" name="action_limit" value="single" checked={selectedActionLimit === 'single'} disabled={locked} onChange={() => callback('single')}/>
            Single Action
        </label>
        </span>
        <span className="custom_radio">
        <label>
            <input className="visually-hidden" type="radio" name="action_limit" value="points" checked={selectedActionLimit === 'points'} disabled={locked} onChange={() => callback('points')}/>
            Action Points
        </label>
        </span>
    </fieldset>
}

type InitiativeStylePanelProps = {
    selectedInitiativeStyle: InitiativeStyle;
    locked: boolean,
    callback: (value: InitiativeStyle) => void;
}

const InitiativeStylePanel = ({ selectedInitiativeStyle, locked, callback }: InitiativeStylePanelProps) => {
    return <fieldset disabled={locked}>
        <legend>Initiative Style</legend>
        <span className="custom_radio">
        <label>
            <input className="visually-hidden" type="radio" name="initiative_style" value="fixed" checked={selectedInitiativeStyle === 'fixed'} disabled={locked} onChange={() => callback('fixed')}/>
            Fixed
        </label>
        </span>
        <span className="custom_radio">
        <label>
            <input className="visually-hidden" type="radio" name="initiative_style" value="random" checked={selectedInitiativeStyle === 'random'} disabled={locked} onChange={() => callback('random')}/>
            Random
        </label>
        </span>
        <span className="custom_radio">
        <label>
            <input className="visually-hidden" type="radio" name="initiative_style" value="players_choice" checked={selectedInitiativeStyle === 'players_choice'} disabled={locked} onChange={() => callback('players_choice')}/>
            Player's Choice
        </label>
        </span>
    </fieldset>
}
