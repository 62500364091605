import React, { useState } from "react";
import { createPortal } from 'react-dom';
import { Configuration } from './configuration'
import { ConfigurationPanel } from './ConfigurationPanel';

type ConfigurationPanelButtonProps = {
  initialConfiguration: Configuration,
  restartFn: (Configuration) => void
}

export default function ConfigurationPanelButton({ initialConfiguration, restartFn } : ConfigurationPanelButtonProps) {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <button onClick={() => setShowModal(true)}>⚙️</button>
      
      {showModal && createPortal(
        <ConfigurationPanel initialConfiguration={initialConfiguration} closeFn={() => setShowModal(false)} applyFn={(configuration) => {setShowModal(false); restartFn(configuration)}}/>,
        document.body
      )}
    </>
  );
}