export type Ability = {
    id: number,
    label: string,
    icon: string
}

export type Character = {
    id: number,
    name: string,
    icon: string,
    abilities: Ability[],
    hitPoints: number,
    actionPoints: number,
    actionPointsMax: number,
    allianceId: number,
    controllerId: number
}

export function buildCharacters() {
    return [
        {
            id: 1,
            name: "Melee 1",
            icon: "🥷",
            abilities: [
                {
                    id: 1,
                    icon: "🗡️",
                    label: "Swing"
                },
                {
                    id: 2,
                    icon: "🪄",
                    label: "Cast"
                }
            ],
            hitPoints: 25,
            actionPoints: 3,
            actionPointsMax: 5,
            allianceId: 1,
            controllerId: 1
        },
        {
            id: 2,
            name: "Caster 1",
            icon: "🧙🏼",
            abilities: [
                {
                    id: 1,
                    icon: "🗡️",
                    label: "Swing"
                },
                {
                    id: 2,
                    icon: "🪄",
                    label: "Cast"
                }
            ],
            hitPoints: 10,
            actionPoints: 3,
            actionPointsMax: 3,
            allianceId: 1,
            controllerId: 1
        },
        {
            id: 3,
            name: "Healer 1",
            icon: "🧝‍♂️",
            abilities: [
                {
                    id: 1,
                    icon: "🗡️",
                    label: "Swing"
                },
                {
                    id: 2,
                    icon: "🪄",
                    label: "Cast"
                },
                {
                    id: 3,
                    icon: "💊",
                    label: "Heal"
                }
            ],
            hitPoints: 15,
            actionPoints: 3,
            actionPointsMax: 3,
            allianceId: 1,
            controllerId: 1
        },
        {
            id: 4,
            name: "Ghost",
            icon: "👻",
            abilities: [
                {
                    id: 4,
                    icon: "🗡️",
                    label: "Wail"
                }
            ],
            hitPoints: 15,
            actionPoints: 3,
            actionPointsMax: 3,
            allianceId: 2,
            controllerId: 2
        },
        {
            id: 5,
            name: "Animated Cheese Wheel",
            icon: "🧀",
            abilities: [
                {
                    id: 1,
                    icon: "🗡️",
                    label: "Crush"
                }
            ],
            hitPoints: 15,
            actionPoints: 3,
            actionPointsMax: 3,
            allianceId: 2,
            controllerId: 2
        }
    ];
}