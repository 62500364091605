export type Quadrant = 'cycle_based' | 'round_based' | 'queue_based' | 'clocked_queue_based'

export type CommandStyle = 'immediate' | 'intentions';
// export const COMMAND_STYLE = 'intentions';

export type InitiativeStyle = 'fixed' | 'random' | 'players_choice';

// export const INITIATIVE_STYLE = 'fixed';
// export const INITIATIVE_STYLE = 'random';
// export const INITIATIVE_STYLE = 'players_choice';

// export const PLAYER_ORDER = 'fixed';

// export const CLOCKING = "unclocked";
// export const CLOCKING = CLOCKED;

export type ActionLimit = 'single' | 'points';
// export const ACTION_LIMIT= 'single';
// const ACTION_LIMIT= 'points';

// Immediate Action, initiative, unclocked

export type Configuration = {
    id: string,
    label: string
    commandStyle: CommandStyle,
    initiativeStyle: InitiativeStyle,
    actionLimit: ActionLimit
}

export const presetConfigurations: {[index: string]: Configuration} = {
    'bards_tale_i_to_iii': {
        id: 'bards_tale_i_to_iii',
        label: "Bard's Tale (1-3)",
        commandStyle: 'intentions',
        initiativeStyle: 'random',
        actionLimit: 'single'
    },
    'might_and_magic_i_to_ii': {
        id: 'might_and_magic_i_to_ii',
        label: "Might and Magic (1-2)",
        commandStyle: 'immediate',
        initiativeStyle: 'random',
        actionLimit: 'single'
    },
    'might_and_magic_x': {
        id: 'might_and_magic_x',
        label: "Might and Magic X: Legacy",
        commandStyle: 'immediate',
        initiativeStyle: 'players_choice',
        actionLimit: 'single'
    }
}