import React, { useState, useEffect } from "react";
import { useLocalStorage } from 'usehooks-ts'
import "./styles.css";
import {buildCharacters, Character, Ability} from "./characters";
import TurnOrder from "./TurnOrder";
import {Configuration, InitiativeStyle, ActionLimit, presetConfigurations} from "./configuration";
import ConfigurationPanelButton from "./ConfigurationPanelButton";

type Animation = {
  applied: boolean,
  sequence: number
}
type CharacterAnimations = {
  damaged?: Animation,
  healed?: Animation
}
type IndexedCharacterAnimations = { [index: number]: CharacterAnimations};

const App = () => {
  const searchParams = new URLSearchParams(document.location.search)

  const initialCharacters = buildCharacters();
  const [configuration, setConfiguration] = useLocalStorage('combat-configuration-3', { id: 'custom', label: 'Custom', commandStyle: 'immediate', initiativeStyle: 'fixed', actionLimit: 'single'} as Configuration);
  const [turnOrder, setTurnOrder] = useState(new TurnOrder(initialCharacters, configuration));
  const [events, setEvents] = useState([]);
  const [characters, setCharacters] = useState(initialCharacters);
  const [selectedCharacters, setSelectedCharacters] = useState([]);
  const [characterAnimations, setCharacterAnimations] = useState<IndexedCharacterAnimations>({1:{}, 2: {}, 3: {}, 4: {}, 5: {}});
  const [currentPlayerId, setCurrentPlayerId] = useState(null);
  const [issuedCommands, setIssuedCommands] = useState([]);
  const [actingCharacters, setActingCharacters] = useState([]);

  const restart = (configuration) => {
    console.log("restarting combat", configuration);
    setConfiguration(configuration);
    const turnOrder = new TurnOrder(initialCharacters, configuration);
    setTurnOrder(turnOrder);
    setEvents([]);
    setCharacters(initialCharacters);
    setSelectedCharacters([]);
    setCharacterAnimations({1:{}, 2: {}, 3: {}, 4: {}, 5: {}});
    // setIssuedCommands([]);
    const events = turnOrder.startCombat();
    processEvents(events);
  }

  useEffect(() => {
    const presetConfiguration: Configuration = presetConfigurations[searchParams.get('preset')];
    if (presetConfiguration) {
      restart(presetConfiguration);
    } else if (configuration.id !== 'custom') {
      restart(configuration);
    } else {
      const newConfiguration: Configuration = {
        id: 'custom',
        label: 'Custom',
        commandStyle: searchParams.get('commandStyle') || configuration.commandStyle,
        initiativeStyle: searchParams.get('initiativeStyle') || configuration.initiativeStyle,
        actionLimit: searchParams.get('actionLimit') || configuration.actionLimit
      } as Configuration;
      restart(newConfiguration);
    }
  }, []);

  const indexArrayBy = (array, keyFunc) => {
    return array.reduce((a, e) => { 
      a[keyFunc(e)] = e;
      return a;
    }, {});
  }

  const updateArray = (source, updates, keyFunc) => {
    const updatesByKey = indexArrayBy(updates, keyFunc);
    return source.map(e => {
      const sourceKey = keyFunc(e);
      return updatesByKey[sourceKey] ?? e;
    });
  };

  const processEvents = (newEvents) => {
    const eventsToProcess = events.concat(newEvents);
    var updatedCharacters = characters;
    const updatedCharacterAnimations = characterAnimations; // incomingCharacterAnimations ?? Object.assign({}, characterAnimations);
    const results = eventsToProcess.reduce((state, event) => {
      if (state.waitForAnimations) {
        return { waitForAnimations: true, deferredEvents: state.deferredEvents.concat([event])};
      } else {
        console.log("processing event", event);
        switch (event.id) {
          case 'character_damage':
            setActingCharacters(event.sources.map(character => character.id));
            updatedCharacters = updateArray(updatedCharacters, event.targets, character => character.id);
            event.targets.forEach(character => {
              const animation = updatedCharacterAnimations[character.id].damaged || { applied: false, sequence: 0}
              animation.applied = true;
              animation.sequence += 1;
              updatedCharacterAnimations[character.id].damaged = animation;
            });
            console.log("animating damage on", event.targets);
            // setCharacterAnimations(newCharacterAnimations);
            return { waitForAnimations: true, deferredEvents: [{ id: 'clear_acting_characters'}]}
          case 'character_heal':
            setActingCharacters(event.sources.map(character => character.id));
            updatedCharacters = updateArray(characters, event.targets, character => character.id);
            event.targets.forEach(character => {
              const animation = updatedCharacterAnimations[character.id].healed || { applied: false, sequence: 0}
              animation.applied = true;
              animation.sequence += 1;
              updatedCharacterAnimations[character.id].healed = animation;
            });
            // setCharacterAnimations(newCharacterAnimations);
            return { waitForAnimations: true, deferredEvents: [{ id: 'clear_acting_characters'}]}
          case 'clear_acting_characters':
            setActingCharacters([]);
            return { waitForAnimations: false, deferredEvents: []};
          case 'resource_used':
            console.log("resource_used", event.targets);
            updatedCharacters = updateArray(characters, event.targets, character => character.id);
            // setCharacters(updatedCharacters);
            // event.targets.forEach(character => {
            //   updatedCharacterAnimations[character.id].damaged = true;
            // });
            // setCharacterAnimations(newCharacterAnimations);
            return { waitForAnimations: false, deferredEvents: []};
          case 'player_issue_commands':
            updatedCharacters = updateArray(characters, event.ready, character => character.id);
            setCurrentPlayerId(event.playerId);
            setSelectedCharacters(event.ready.map(character => character.id));

            // Make sure each character has at least one selected ability
            const charactersWithNoCommand = characters.filter((character) => !issuedCommands.some((command) => command.source.id === character.id));
            setIssuedCommands(issuedCommands.concat(
              charactersWithNoCommand.map(character => (
                { 
                  source: character, 
                  ability: character.abilities[0]
                }))
            ));
            break;
          case 'combat_start':
            break;
          case 'round_start': 
            break;
          case 'round_end': 
            break;
          case 'turn_start': 
            break;
          case 'turn_end': 
            break;
          default:
            console.error("unknown event", event);
        }
        return { waitForAnimations: false, deferredEvents: []}
      }
    }, { waitForAnimations: false, deferredEvents: []});
    setCharacters(updatedCharacters);
    setCharacterAnimations(updatedCharacterAnimations);
    setEvents(results.deferredEvents);
  }

  const issueCommand = (character: Character, ability: Ability) => {
    console.log(`player ${currentPlayerId} commands ${character.name} to ${ability.label}`);
    if (configuration.commandStyle === 'immediate') {
      const events = turnOrder.submitCommands([{ source: character, ability: ability }]);
      setTurnOrder(turnOrder);
      setSelectedCharacters([]);
      processEvents(events);
    } else if (configuration.commandStyle === 'intentions') {
      // const updatedCharacterActions = Object.assign({}, characterActions);
      // updatedCharacterActions[character.id] = ability;
      setIssuedCommands(issuedCommands.map(command => command.source.id === character.id ? {
        source: character,
        ability: ability
      } : command ));
    } else {
      console.error('unknown command style', configuration.commandStyle);
    }
  }

  const submitCommands = () => {
    // const commands = Object.entries(characterActions).map(([k, v], i) =>  {
    //   return {source: characters.find(character => character.id === Number.parseInt(k)), ability: v };
    // });
    const events = turnOrder.submitCommands(issuedCommands);
    setTurnOrder(turnOrder);
    setSelectedCharacters([]);
    processEvents(events);
  }

  const updatedCharacterAnimations = characterAnimations; // Object.assign({}, characterAnimations);

  const animationFinished = (animationName, characterId) => {
    console.log("animationFinished", animationName, characterId);

    switch (animationName.split('-')[0]) {
      case 'showDamage':
        updatedCharacterAnimations[characterId]['damaged'].applied = false
        break;
      case 'spin':
      case 'spinner':
        updatedCharacterAnimations[characterId]['healed'].applied = false
        break;
    }
    // updatedCharacterAnimations[characterId][animationName.split('-')[0]].applied = false;
    console.log("updatedCharacterAnimations", updatedCharacterAnimations);
    // setCharacterAnimations(newCharacterAnimations);
    const allAnimationsFinished = Object.values(updatedCharacterAnimations).every(animations => Object.values(animations).every(animation => !animation.applied));
    console.log("allAnimationsFinished?", allAnimationsFinished);
    if (allAnimationsFinished) {
      processEvents([]);
    } else {
      // setCharacterAnimations(updatedCharacterAnimations);
    }
  }

  const alliances = [
    { id: 1, playerId: 1, characters: characters.filter(c => c.allianceId === 1) },
    { id: 2, playerId: 2, characters: characters.filter(c => c.allianceId === 2) },
  ]

  return (
    <>
      <ConfigurationPanelButton initialConfiguration={configuration} restartFn={(configuration) => restart(configuration) }/>
      <div className="allianceBoards">
      {alliances.map(alliance => (
        <div className="allianceBoard">
          {alliance.characters.map((character, index) => (
            <CharacterCard
              key={character.id}
              id={character.id}
              icon={character.icon}
              name={character.name}
              hitPoints={character.hitPoints}
              actionPoints={character.actionPoints}
              actionPointsMax={character.actionPointsMax}
              abilities={character.abilities}
              actionLimit={configuration.actionLimit}
              selected={selectedCharacters.includes(character.id)}
              ready={selectedCharacters.includes(character.id)}
              animations={characterAnimations[character.id] ?? {} as CharacterAnimations}
              selectedAction={(configuration.commandStyle === 'intentions') && (issuedCommands.find(command => command.source.id === character.id)?.ability ?? {})}
              isActing={actingCharacters.includes(character.id)}
              issueCommand={issueCommand}
              animationFinished={animationFinished}
            />
          ))}
          { (configuration.commandStyle === 'intentions') ?
            <button disabled={alliance.playerId !== currentPlayerId} onClick={submitCommands}>Go</button>
          : '' }
      </div>
    ))}
    </div>
    </>
  );
}

type CharacterCardProps = {
  key: number,
  id: number,
  icon: string,
  name: string,
  hitPoints: number,
  actionPoints: number,
  actionPointsMax: number,
  abilities: Ability[],
  actionLimit: ActionLimit,
  selected: boolean,
  ready: boolean,
  animations: CharacterAnimations,
  selectedAction: Ability,
  isActing: boolean,
  issueCommand: (Character, Ability) => void,
  animationFinished: (string, number) => void
}

const CharacterCard = (props: CharacterCardProps) => {
  const cssAnimations = Object.entries(props.animations).filter(([animation, settings]) => settings.applied).flatMap(([animation, settings]) => {
    switch (animation) {
      case 'damaged':
        return ['damaged-' + settings.sequence % 2]
      default :
        return []
    }
  });

  const texiconAnimations = Object.entries(props.animations).filter(([animation, settings]) => settings.applied).flatMap(([animation, settings]) => {
    switch (animation) {
      case 'healed':
        return [{ class: "spin-" + settings.sequence % 2, graphic: '💊' }]
      default :
        return []
    }
  });

  const iconClassList = props.isActing ? 'halo' : '';

  function characterAbilityClicked(character, ability) {
    props.issueCommand(character, ability);
  }

  function animationEnd(event) {
    console.log(event);
    props.animationFinished(event.animationName, props.id);
  }

  function texiconAnimationEnd(event) {
    console.log(event);
    props.animationFinished(event.animationName, props.id);
  }

  return (
    <div className={`character-card ${props.selected ? 'selected' : ''} ${cssAnimations.join(' ')}`}
    onAnimationEnd={animationEnd}>
      <div className="character-card-contents">
        <div className="left">
        <h1 className={iconClassList}>{props.icon}</h1>
        <div className="character-details">
          <p>{props.name}</p>
          <p>{props.hitPoints}</p>
        </div>
        </div>
        <div className="right">
          { (props.actionLimit === 'points') ?
          <div className="action-points">
            {[...Array(Math.max(props.actionPoints, props.actionPointsMax)).keys()].map(n => (
              <span key={n} className={(n >= props.actionPoints ? 'faded' : '') }>🔷</span>
            ))}
          </div>
            : ''
          }
          <div className="ability-list">
            {props.abilities.map(ability => (
              <button key={ability.id} className={`ability ${props.selectedAction.id === ability.id ? 'selected' : ''}`} disabled={!props.ready} 
                  onClick={() => characterAbilityClicked(props, ability)}>
                <span>{ability.label}</span>
                <span>{ability.icon}</span>
              </button>
            ))}
          </div>
          {/* <button onClick={() => setShowAge(!showAge)}>
            Toggle Age 
          </button>
          {showAge && <p>Age: {props.age}</p>} */}
        </div>
        {
          (texiconAnimations.length > 0) ?
          <div className="animation-holder" style={{position: "absolute", width: "100%", height: "100%", top: 0, left: 0, textAlign: "center"}}>
          {texiconAnimations.map(texticonAnimation => (
              <div className="spinner" onAnimationEnd={texiconAnimationEnd}>{texticonAnimation.graphic}</div>
            ))}
          </div>
          : ''
        }
      </div>
    </div>
  ) 
}

export default App;