import {Configuration} from "./configuration";
import {Ability, Character} from "./characters";

export type Player = {
  id: number,
  name: string
}

export type Command = {
  source: Character,
  ability: Ability
}

export type EventType = CharacterEventType | PlayerEventType | TimelineEventType
export type Event = CharacterEvent | PlayerEvent | TimelineEvent

export type CharacterEventType = 'resource_used' | 'character_damage' | 'character_heal';
export type CharacterEvent = {
  id: CharacterEventType,
  type?: 'action_points',
  sources: Character[],
  targets: Character[]
}

export type PlayerEventType = 'player_issue_commands';
export type PlayerEvent = {
  id: PlayerEventType,
  playerId: number,
  ready: Character[],
  intentions: boolean
}

export type TimelineEventType = 'combat_start' | 'round_start' | 'round_end' | 'turn_start' | 'turn_end' | 'intentions_start' | 'intentions_next' | 'intentions_end';
export type TimelineEvent = {
  id: TimelineEventType,
  targets?: Character[]
}

type TimelineProcessor = (state: TurnOrder) => Event[];
type TimelinePoint = {
  name: TimelineEventType;
  processors: TimelineProcessor[];
}
const timelinePoint = (name: TimelineEventType): TimelinePoint => {
  return { name, processors: [] }
}

export default class TurnOrder {
    configuration: Configuration;
    players: Player[];
    timelinePoints: {
      [key in TimelineEventType]?: TimelinePoint
    } = {
      'combat_start': timelinePoint('combat_start'),
      'round_start': timelinePoint('round_start'),
      'round_end': timelinePoint('round_end'),
      'intentions_start': timelinePoint('intentions_start'),
      'intentions_next': timelinePoint('intentions_next'),
      'intentions_end': timelinePoint('intentions_end'),
      'turn_start': timelinePoint('turn_start'),
      'turn_end': timelinePoint('turn_end'),
    }
    currentRoundNumber: number;
    currentPlayerIndex: number;
    characters: Character[];
    readyCharacterIndex: number;
    readyCharacters: Character[];
    // events: Event[] = [];
    currentCharacterId: number;
    commandQueues: { [key: number]: Command[] } = {};

    constructor(characters: Character[], configuration: Configuration) {
      this.configuration = configuration;
      this.players = [
        {id: 1, name: 'Player 1'},
        {id: 2, name: 'Player 2'}
      ];
      this.currentPlayerIndex = -1;
      this.characters = characters;
      this.readyCharacterIndex = -1;
      this.readyCharacters = [];
    }
  
    updateCharacter(id: number, mutator: (character: Character) => void): Character {
      let updatedCharacter: Character;
      const updatedCharacters = this.characters.map(character => {
        if (character.id === id) {
          updatedCharacter = Object.assign({}, character);
          mutator(updatedCharacter);
          return updatedCharacter;
        } else {
          return character;
        }
      });
      this.characters = updatedCharacters;
      return updatedCharacter;
    }
  
    updateCharacters(filter: (character: Character) => boolean, mutator: (character: Character) => void): Character[] {
      const revisions = this.characters.map(character => {
          if (filter(character)) {
            const updatedCharacter = Object.assign({}, character);
            mutator(updatedCharacter);
            return { character: updatedCharacter, updated: true };
          } else {
            return { character: character, updated: false}
          }
      });
      this.characters = revisions.map(revision => revision.character);
      return revisions.filter(revision => revision.updated).map(revision => revision.character);
    }
  
    findCharacter(id: number): Character {
      return this.characters.find(character => character.id === id)
    }
  
    findCharacters(criteria: (character: Character) => boolean): Character[] {
      return this.characters.filter(criteria);
    }
  
    hasNextTurn(): boolean {
      return true;
    }
  
    allyOf(character: Character): (character: Character) => boolean {
      return (otherCharacter: Character) => character.controllerId === otherCharacter.controllerId;
    }
  
    enemyOf(character: Character): (character: Character) => boolean {
      return (otherCharacter) => character.controllerId !== otherCharacter.controllerId;
    }
  
    registerTimelineProcessor(pointName: TimelineEventType, processor: TimelineProcessor) {
      this.timelinePoints[pointName].processors.push(processor);
    }

    advanceToPoint(pointName: TimelineEventType): Event[] {
      return this.timelinePoints[pointName].processors.flatMap(processor => processor(this));
    }

    startCombat(): Event[] {
      this.initializeTimeline();
      const events: Event[] = [];
      events.push( ...this.advanceToPoint('combat_start'));
      events.push( ...this.advanceToPoint('round_start'));
      switch (this.configuration.commandStyle) {
        case 'immediate': 
          events.push( ...this.advanceToPoint('turn_start'));
          break;
        case 'intentions':
          events.push( ...this.advanceToPoint('intentions_start'));
          break;
      }

      //}
      // this.events.push({
      //   id: 'round_start',
      // });

      return events;
    }

    initializeTimeline() {
      // All configurations are unmeasured and clocked at the moment

      this.registerTimelineProcessor('combat_start', (state) => {
        state.currentRoundNumber = 0;
        return [{ id: 'combat_start' }];
      });

      this.registerTimelineProcessor('round_start', (state) => {
        const events: Event[] = [];
        state.currentRoundNumber++;
        events.push({ id: 'round_start' });
        switch (state.configuration.initiativeStyle) {
          case 'fixed':
            state.readyCharacters = [...this.characters];
            state.readyCharacterIndex = 0;
            break;
          case 'random':
            state.readyCharacters = [...this.characters];
            state.readyCharacters.sort(e => 0.5 - Math.random());
            console.log("shuffling characters", state.readyCharacters.map(character => character.name));
            state.readyCharacterIndex = 0;
            break;
          case 'players_choice':
            break;
        }
        state.currentPlayerIndex = 0;
        state.commandQueues = {};
        return events;
      });

      this.registerTimelineProcessor('round_end', (state) => {
        return [{ id: 'round_end' }];
      });

      this.registerTimelineProcessor('turn_start', (state) => {
        const events: Event[] = [];
        switch (state.configuration.initiativeStyle) {
          case 'fixed':
          case 'random':
            var readyCharacter = state.readyCharacters[state.readyCharacterIndex];
            // Start of turn
            console.log("start of turn for", readyCharacter);
            if (state.configuration.actionLimit === 'points') {
              readyCharacter = state.updateCharacter(readyCharacter.id, character => {
                character.actionPoints = 3;
              })
            }
            state.currentCharacterId = readyCharacter.id;
            events.push({id: 'turn_start', targets: [readyCharacter] });
            events.push({
              id: 'player_issue_commands',
              playerId: readyCharacter.controllerId,
              ready: [readyCharacter],
              intentions: false
            });
            break;
          case 'players_choice':
            const currentPlayer = state.players[state.currentPlayerIndex];
            switch (this.configuration.actionLimit) {
              case 'points':
                state.readyCharacters = state.updateCharacters(character => character.controllerId === currentPlayer.id, character => {
                  character.actionPoints = 3;
                });
                break;
              case 'single':
                state.readyCharacters = state.findCharacters(character => character.controllerId === currentPlayer.id);
                break;
            }
            events.push({
              id: 'turn_start',
              targets: state.readyCharacters
            });
            events.push(
              {
                id: 'player_issue_commands',
                playerId: state.players[state.currentPlayerIndex].id,
                ready: state.readyCharacters,
                intentions: false
              }
            );
            break;                  
        }
        return events;
      });

      this.registerTimelineProcessor('turn_end', (state) => {
        const events: Event[] = [];
        const currentCharacter = state.findCharacter(state.currentCharacterId);
        console.log("end of turn for", currentCharacter);
        events.push({
          id: 'turn_end',
          targets: [currentCharacter]
        });
        state.currentCharacterId = null;
        return events;
      });

      if (this.configuration.commandStyle === 'intentions') {
        this.registerTimelineProcessor('intentions_start', (state) => {
          state.currentPlayerIndex = -1;
          // const currentPlayer = state.players[this.currentPlayerIndex];
          return [
            {
              id: 'intentions_start'
            },
            ...state.advanceToPoint('intentions_next')
          ];
        });
        this.registerTimelineProcessor('intentions_next', (state) => {
          this.currentPlayerIndex = this.currentPlayerIndex + 1;
          if (state.currentPlayerIndex < state.players.length) {
            const currentPlayer = state.players[this.currentPlayerIndex];
            return [
              {
                id: 'player_issue_commands',
                playerId: currentPlayer.id,
                ready: state.findCharacters((character) => character.controllerId === currentPlayer.id),
                intentions: true
              }
            ]
          } else {
            return state.advanceToPoint('intentions_end');
          }
        });
        this.registerTimelineProcessor('intentions_end', (state) => {
          return [
            {
              id: 'intentions_end'
            },
            ...state.processAllSubmittedCommands()
          ]
        });
      } else {
        this.registerTimelineProcessor('intentions_start', (state) => []);
        this.registerTimelineProcessor('intentions_next', (state) => []);
        this.registerTimelineProcessor('intentions_end', (state) => []);
      }
    }

    submitCommands(commands: Command[]): Event[] {
      switch (this.configuration.commandStyle) {
        case 'immediate':
          return this.nextTurn(commands[0]);
        case 'intentions':
          const currentPlayer = this.players[this.currentPlayerIndex];
          this.commandQueues[currentPlayer.id] = commands;
          return this.advanceToPoint('intentions_next');

          // if (this.currentPlayerIndex + 1 === this.players.length) {
          //   this.currentPlayerIndex = 0;
          //   return this.processAllSubmittedCommands();
          // } else {
          //   this.currentPlayerIndex = (this.currentPlayerIndex + 1);
          //   return this.advanceToPoint('intentions_start'); 
          // }
      }
    }

    processAllSubmittedCommands(): Event[] {
      const events: Event[] = [];
      let endOfRound = false;
      events.push(...this.advanceToPoint('turn_start'));

      do {
        const commandEvent = events.pop();
        if (commandEvent.id === 'player_issue_commands' && commandEvent.intentions === false) {
          const command = this.commandQueues[commandEvent.playerId].find(command => command.source.id === commandEvent.ready[0].id);
          events.push(...this.nextTurn(command));
        } else {
          events.push(commandEvent);
          endOfRound = true;
        }
      } while (!endOfRound);
      return events;
    }

    nextTurn(command: Command | null) {
      const events: Event[] = [];
      if (command !== null) {
        if (this.configuration.actionLimit === 'points') {
          // consume the points used
          const updatedCharacter = this.updateCharacter(command.source.id, character => {
            character.actionPoints = Math.max(character.actionPoints - 1, 0);
          });
          events.push(
            {
              id: 'resource_used',
              type: 'action_points',
              sources: [],
              targets: [updatedCharacter]
            }
          );
        }
  
        const actingCharacter = this.findCharacter(command.source.id);

        switch (command.ability.id) {
          case 1:
          case 2:
            // target a random enemy
            const enemies = this.characters.filter(this.enemyOf(actingCharacter));
            const enemy = enemies[Math.floor(Math.random()*enemies.length)];
            const updatedCharacter = this.updateCharacter(enemy.id, character => {
              character.hitPoints = character.hitPoints - 1;
            });
            
            events.push(
              {
                id: 'character_damage',
                sources: [actingCharacter],
                targets: [updatedCharacter]
              }
            );
            break;
          case 3:
            const updatedCharacters = this.updateCharacters(this.allyOf(actingCharacter), character => {
              character.hitPoints = character.hitPoints + 1;
            });
            
            events.push(
              {
                id: 'character_heal',
                sources: [actingCharacter],
                targets: updatedCharacters
              }
            );
            break;
          case 4: 
            const updatedCharacters2 = this.updateCharacters(this.enemyOf(actingCharacter), character => {
              character.hitPoints = character.hitPoints - 1;
            });
            
            events.push(
              {
                id: 'character_damage',
                sources: [actingCharacter],
                targets: updatedCharacters2
              }
            );
        }
      }
  
      const noMoreActions = this.configuration.actionLimit !== 'points' || command === null || this.findCharacter(command.source.id).actionPoints === 0;
  
      if (this.configuration.initiativeStyle === 'fixed' || this.configuration.initiativeStyle === 'random') {
        if (noMoreActions) {
          events.push( ...this.advanceToPoint('turn_end'));
          if (this.readyCharacterIndex + 1 === this.readyCharacters.length) {
            events.push( ...this.advanceToPoint('round_end'));
            events.push( ...this.advanceToPoint('round_start'));
            if (this.configuration.commandStyle === 'immediate') {
              console.log("next character", this.readyCharacterIndex);
              events.push( ...this.advanceToPoint('turn_start'));
            } else if (this.configuration.commandStyle === 'intentions') {
              events.push( ...this.advanceToPoint('intentions_start'));
            }
          } else {
            this.readyCharacterIndex = (this.readyCharacterIndex + 1) % (this.readyCharacters.length);
            console.log("next character", this.readyCharacterIndex);
            events.push( ...this.advanceToPoint('turn_start'));
          }
        } else {
          // No change of turns
          console.log("still in turn for", this.readyCharacters[this.readyCharacterIndex]);
          const readyCharacter = this.findCharacter(this.readyCharacters[this.readyCharacterIndex].id);
          events.push({
            id: 'player_issue_commands',
            playerId: readyCharacter.controllerId,
            ready: [readyCharacter],
            intentions: false
          });
        }
      } else if (this.configuration.initiativeStyle === 'players_choice') {
        const currentPlayer = this.players[this.currentPlayerIndex];
        const currentCharacter = this.findCharacter(command.source.id);
        if (this.configuration.actionLimit === 'single') {
          this.readyCharacters = this.readyCharacters.filter(character => character.id !== currentCharacter.id);
          console.log("end of turn for", currentCharacter);
          events.push({
            id: 'turn_end',
            targets: [currentCharacter]
          });
        } else if (this.configuration.actionLimit === 'points') {
          if (currentCharacter.actionPoints === 0) {
            console.log("end of turn for", currentCharacter);
            events.push({
              id: 'turn_end',
              targets: [currentCharacter]
            });
          }
          this.readyCharacters = this.findCharacters(character => character.controllerId === currentPlayer.id && character.actionPoints > 0);
        }
        if (this.readyCharacters.length === 0) {
          // Advance to the next player
          if (this.currentPlayerIndex + 1 === this.players.length) {
            events.push(...this.advanceToPoint('round_end'));
            events.push(...this.advanceToPoint('round_start'))
          } else {
            this.currentPlayerIndex = this.currentPlayerIndex + 1;
          }
          events.push( ...this.advanceToPoint('turn_start'));
        } else {
          events.push(
            {
              id: 'player_issue_commands',
              playerId: this.players[this.currentPlayerIndex].id,
              ready: this.readyCharacters,
              intentions: false
            }
          );
        }
      }
      return events;
    }
  }
  